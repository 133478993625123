import easydropdown from 'easydropdown';

(function() {

    // --------------------------
    // FAKE FILEUPLOAD FORMFIELD
    // --------------------------

    $('.input__label--fileupload').click(function(e) {
       e.preventDefault();
    });

    $('.input__fake-fileupload').click(function() {
        $(this).parent().find('input.input--file').click();
    })

    $('.input--file').change(function(e){
        let $files      = e.target.files;
        let $fileNames  = [];

        $($files).each(function(key, value) {
            $fileNames.push(value.name)
        })

        $('.input__label--fileupload').addClass('input__label--fileupload-active');

        $(this).next('.input__fake-fileupload').empty().append($fileNames.join(', '));
	});


    // --------------------------
    // FAKE BUTTONS RADIO FORMFIELD
    // --------------------------

    $('.radio__group').each(function() {
        let $thisRadioGroup = $(this),
            $fakebuttons = $thisRadioGroup.find('.fakebutton');

        $fakebuttons.each(function () {
            $(this).find('.radio').change(function () {
                if($(this).is(':checked')) {
                    $fakebuttons.removeClass('checked');
                    $(this).closest('.fakebutton', $thisRadioGroup).addClass('checked');
                }
            })
        })
    })
})();


// --------------------------
// SELECT FORMFIELD
// --------------------------
let allSelects = Array.prototype.slice.call(document.querySelectorAll('.select'));
allSelects.forEach(el => {
	let dd = easydropdown(el, {
		behavior: {
			maxVisibleItems: 8
		}
	});

	// tab on keyup isn't doable as we lose focus immediately,
	// tab event is received on keyup when tabbing INTO the select
	el.addEventListener('keydown', e => {
		let k = e.keyCode || e.which;
		if (k === 9) {
			dd.close();
		}
	});
})

// --------------------------
// RANGE INPUT BUBBLE
// --------------------------
window.setBubble = function(range, bubble) {  // must be available ion angular context
	const val = range.value;
	const min = range.min ? range.min : 0;
	const max = range.max ? range.max : 100;
	const newVal = Number(((val - min) * 100) / (max - min));
	bubble.innerHTML = val + " Euro";

	// Sorta magic numbers based on size of the native UI thumb
	bubble.style.left = `calc(${newVal}% + (${ (8 - newVal * 0.15) * 1.625 }px))`;
}
let range = Array.prototype.slice.call(document.querySelectorAll('.js-range'));
range.forEach(el => {
	let bubble = el.parentNode.querySelector('.js-bubble');

	el.addEventListener("change", () => {
		setBubble(el, bubble);
	});
	setBubble(el, bubble);
});