/**
 * To test this in an environment where the Google codes aren't embedded, you can use this snippet:
dataLayer = [];
dataLayer = new Proxy(dataLayer, {
	set: function(obj, prop, value) {
		console.log(`Setting ${ prop } to`, value.event);
		obj[prop] = value;
		return true;
	}
});
 * Copy and paste it into the console and the push is logged in the console.
 */

export default function NP_TM(config) {
	if (!(this instanceof NP_TM)) {
		return new NP_TM(config);
	}
	let defaulConfig = {
		selectorTrigger: '.js-gtm',
		attrEvent: 'data-gtm',
		additionalEvents: {},
		manualAttachments: (_this) => {}
	};
	this.config = Object.assign({}, defaulConfig, config);

	let defaulEvents = {
		// header, used for eks/smf
		headerLogo: { event: 'header_click_logo' },

		// Footer, used for eks/smf
		footerLogo: { event: 'footer_click_logo' },
		footerPhone: { event: 'footer_click_telefonnummer' },
		footerEmail: { event: 'footer_click_email' },
		footerLegalTos: { event: 'footer_click_nutzungsbedingungen' },
		footerLegalPrivacy: { event: 'footer_click_datenschutz' },
		footerLegalImprint: { event: 'footer_click_impressum' },
		footerCookie: { event: 'footer_click_cookie_hinweis' },
	};

	this.events = Object.assign({}, this.config.additionalEvents, defaulEvents);

	// Click events triggering tag manager events
	let clickEvents = Array.prototype.slice.call(document.querySelectorAll(this.config.selectorTrigger));
	clickEvents.forEach(el => {
		let event = el.getAttribute(this.config.attrEvent);
		if (event) {
			el.addEventListener('click', ev => {
				this.push(event);
			});
		}
	});

	this.config.manualAttachments(this);
}

/**
 * Pushing events into dataLayer and _mtm respectively.
 * 
 * @param	mixed	ids		An array of events or an event as string
 * @param	mixed	val		An optional value to push
 */
NP_TM.prototype.push = function(ids, val) {
	if (!Array.isArray(ids)) {
		ids = [ ids ];
	}
	
	if (typeof(dataLayer) !== 'undefined') {
		ids.forEach(id => {
			let definedEvent = typeof(this.events[id]) !== 'undefined';

			// Check for dynamic event name
			let rePlaceholder = id.match(/___(.+?)___/i);
			if (rePlaceholder) {
				definedEvent = typeof(this.events[id.replace(RegExp.$1, 'placeholder')]) !== 'undefined';
			}

			if (definedEvent) {
				let eventName = undefined;
				if (rePlaceholder) {
					eventName = this.events[id.replace(RegExp.$1, 'placeholder')].event.replace('___placeholder___', RegExp.$1);
				} else {
					eventName = this.events[id].event;
				}

				if (!val) {
					dataLayer.push({ event: eventName });
				} else {
					let json = { conversionValue: val, event: eventName };
					dataLayer.push(json);
				}
			}
		});
	}
	
	if (typeof(_mtm) !== 'undefined') {
		ids.forEach(id => {
			let definedEvent = typeof(this.events[id]) !== 'undefined';

			// Check for dynamic event name
			let rePlaceholder = id.match(/___(.+?)___/i);
			if (rePlaceholder) {
				definedEvent = typeof(this.events[id.replace(RegExp.$1, 'placeholder')]) !== 'undefined';
			}

			if (definedEvent) {
				let eventName = undefined;
				if (rePlaceholder) {
					eventName = this.events[id.replace(RegExp.$1, 'placeholder')].event.replace('___placeholder___', RegExp.$1);
				} else {
					eventName = this.events[id].event;
				}

				if (!val) {
					_mtm.push({ event: eventName });
				} else {
					let json = { conversionValue: val, event: eventName };
					_mtm.push(json);
				}
			}
		});
	}
};