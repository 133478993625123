import tippy from 'tippy.js';

// Tooltip attributes
let tooltipAttr = 'data-tooltip-id';
let tooltipTargetAttr = 'data-tooltip-target-id';

// Tooltip config
let allTooltipTargets = Array.prototype.slice.call(document.querySelectorAll(`[${tooltipTargetAttr}]`));
allTooltipTargets.forEach(tooltipTarget => {
	let tooltipId = tooltipTarget.getAttribute(tooltipTargetAttr);
	let tooltipContainer = document.querySelector(`[${tooltipAttr}="${tooltipId}"]`);
	if(tooltipContainer !== null && tooltipContainer !== undefined) {
		let tooltipTrigger = 'mouseenter';		// set trigger through data-attr 'data-tooltip-trigger'
		if (tooltipContainer.getAttribute('data-tooltip-trigger') == 'click') {
			tooltipTrigger = 'click';
			tooltipTarget.setAttribute('style', 'cursor: pointer;');
		}
		tippy(tooltipTarget, {
			allowHTML: true,
			content: tooltipContainer.innerHTML,
			placement: 'top-start',
			maxWidth: 375,
			arrow: true,
			trigger: tooltipTrigger,
			offset: [0, 11],
			popperOptions: {
				modifiers: [
					{
						name: 'flip',
						enabled: false,
					},
				],
			},
		});
	}
});