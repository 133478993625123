// Luxon/ng necessary polyfills
require('./components/polyfills');

window.$ = require('jquery');
window.jQuery = window.$;

require('./blade-components/blade-components');

import NP_TM from './components/np-tagmanager';
window.tag = new NP_TM({
	additionalEvents: {
		// Landing
		productKashBorgen:	{ event: 'produkt_auswahl_kash_borgen' },
		productKashReserv:	{ event: 'produkt_auswahl_kash_reserv' },
		productIkeaFinance:	{ event: 'produkt_auswahl_ikea_finzanierung' },

		productIkeaCreditCard:	{ event: 'produkt_auswahl_ikea_kreditkarte' },
		productFamilyCard:		{ event: 'produkt_auswahl_ikea_family_bezahlkarte' },
		productMasterCard:		{ event: 'produkt_auswahl_ikea_mastercard_karte' },
		productShoppingCard:	{ event: 'produkt_auswahl_ikea_shopping_card' },

		productIncomeProtection: { event: 'produkt_auswahl_einkommensschutz' },
	}
});