/**
 * Function to handle Modals
 * 
 * @config	attrModalId					selector for all modals and data-attribute to connect modals with their buttons
 * @config	attrModalTargetId			selector for all show-Buttons and data-attribute to connect modals with their buttons
 * @config	selectorModalHide			selector for all hide-Buttons, has to be inside of corresponding modal
 * @config	cssClassShow				class to set on modal when shown
 * @config	cssClassBody				class to set on body when modal is shown
 * @config	eventModalShow				name of the show-event dispatched on the modal when it shows up
 * @config	eventModalHide				name of the hide-event dispatched on the modal when it hides
 * @config	selectorModalContent		selector for the content part of the modal
 * @config	selectorModalFixedHeader	selector for the header of the modal
 * @config	cssClassFixedHead			class to set on header in fixed state
 * @config	disableBackDropClick		attribute to disable the backdrop to click the modal
 */
export default function initModals( config ) {
	let defaulConfig = {
		attrModalId: 'data-modal-id',
		attrModalTargetId: 'data-modal-target-id',
		selectorModalHide: '.js-modalClose',
		cssClassShow: 'show',
		cssClassBody: 'modal-context',
		eventModalShow: 'modal.show',
		eventModalHide: 'modal.hide',
		selectorModalContent: '.js-modalContent',
		selectorModalFixedHeader: '.js-modalFixedHeader',
		cssClassFixedHead: 'fixed',
		disableBackDropClick: 'data-disable-backdrop'
	};
	config = Object.assign({}, defaulConfig, config);
	let allModals = Array.prototype.slice.call(document.querySelectorAll(`[${ config.attrModalId }]`));
	if (allModals.length > 0) {
		function fireEvent(elem, eventName) {
			let event = document.createEvent('Event');
			event.initEvent(eventName, true, true);
			elem.dispatchEvent(event);
		}

		//center modal if modal height < screen height
		function positionCenter(modal) {
			let contentBox = modal.querySelector(config.selectorModalContent);
			let	modalHeight = contentBox.offsetHeight;
			let screenHeight = modal.clientHeight - 50;	//minus padding top/bottom
			if (modalHeight < screenHeight) {
				let heightDiff = screenHeight - modalHeight;
				let modalMarginTop = heightDiff / 2;
				contentBox.setAttribute('style', `margin-top: ${modalMarginTop}px;`);
			} else {
				contentBox.setAttribute('style', 'margin-top: 0px;');
			}
		}

		function showModal(modal) {
			allModals.forEach(elem => {
				hideModal(elem);
			});

			modal.classList.add(config.cssClassShow);
			document.body.classList.add(config.cssClassBody);
			positionCenter(modal);
			fireEvent(modal, config.eventModalShow);
		}

		function hideModal(modal) {
			modal.classList.remove(config.cssClassShow);
			document.body.classList.remove(config.cssClassBody);
			fireEvent(modal, config.eventModalHide);
		}

		allModals.forEach(modal => {
			let modalId = modal.getAttribute(config.attrModalId);
			if (modalId != undefined && modalId != null) {
				let allModalShowBtn = Array.prototype.slice.call(document.querySelectorAll(`[${ config.attrModalTargetId }=${ modalId }]`));
				if (allModalShowBtn.length > 0) {
					allModalShowBtn.forEach(btn => {
						btn.addEventListener('click', function (e) {
							e.preventDefault();
							showModal(modal); 
						});
					});
				}

				let allModalHideBtn = Array.prototype.slice.call(modal.querySelectorAll(config.selectorModalHide));
				if (allModalHideBtn.length > 0) {
					allModalHideBtn.forEach( btn => {
						btn.addEventListener('click', function (e) { 
							e.preventDefault();
							hideModal(modal);
						});
					});
				}

				//fixed header in modals
				function fixedHeader() {
					let contentBox = modal.querySelector(config.selectorModalContent);
					let	left = contentBox.offsetLeft;
					let	width = contentBox.offsetWidth;
					let	scrollPos = modal.scrollTop;
					let fixedHead = contentBox.querySelector(config.selectorModalFixedHeader);

					if (fixedHead) {
						if (scrollPos > 25) {
							fixedHead.classList.add(config.cssClassFixedHead);
							fixedHead.setAttribute('style', `left: ${left}px; width: ${width}px;`);

							let fixedHeadHeight = fixedHead.offsetHeight;
							contentBox.setAttribute('style', `padding-top: ${fixedHeadHeight}px;`);
						} else {
							fixedHead.classList.remove(config.cssClassFixedHead);
							fixedHead.removeAttribute('style');
							contentBox.removeAttribute('style');
						}
					}
				}

				modal.addEventListener('scroll', e => { fixedHeader(); });
				window.addEventListener('resize', e => {
					fixedHeader();
					positionCenter(modal);
				});
				
				if (!modal.getAttribute(config.disableBackDropClick)) {
					window.addEventListener('click', function (e) {
						if (e.target == modal) {
							e.preventDefault();
							hideModal(modal);
						}
					});
				}
			}
		});
	}
}